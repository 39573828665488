import { render, staticRenderFns } from "./vr-class-detail.vue?vue&type=template&id=8dec56f4&scoped=true&"
import script from "./vr-class-detail.vue?vue&type=script&lang=js&"
export * from "./vr-class-detail.vue?vue&type=script&lang=js&"
import style0 from "./vr-class-detail.vue?vue&type=style&index=0&id=8dec56f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dec56f4",
  null
  
)

export default component.exports